<!--
 * @Author: wang peng
 * @Date: 2023-2-28
 * @Description: 图表组件
-->
<template>
  <div class="echarts-detail">
    <ykc-detail :titles="[title]" :tips="false" :isLine="true">
      <div slot="cvBody">
        <!-- 图表 -->
        <!-- <div class="line"></div> -->
        <div class="echarts-line">
          <div class="row">
            <!-- <ykc-charts-line
              v-if="isData1"
              :yName="yName[0]"
              :isData="isData1"
              :chartOption="chartOption1"
              :isShowLoading="isShowLoading"></ykc-charts-line> -->
            <ykc-charts-line
              :yName="yName[1]"
              :isData="isData2"
              :chartOption="chartOption2"
              :isShowLoading="isShowLoading"></ykc-charts-line>
            <ykc-charts-line
              :yName="yName[2]"
              :isData="isData3"
              :chartOption="chartOption3"
              :isShowLoading="isShowLoading"></ykc-charts-line>
          </div>
        </div>
      </div>
    </ykc-detail>
  </div>
</template>

<script>
  import YkcChartsLine from './ykcEcharts/YkcChartsLine.vue';
  import { orderPage } from '@/service/apis';

  export default {
    name: 'shareChart',
    components: { YkcChartsLine },
    props: {
      title: {
        type: String,
        default: '',
      },
      page: {
        type: String,
        default: '',
      },
      // 订单号
      tradeNo: {
        type: String,
        default: '',
      },
      // 开始时间
      startTime: {
        type: String,
        default: '',
      },
      // 结束时间
      endTime: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        yName: [],
        // isData1: false, // 图表1是否有数据
        isData2: false, // 图表2是否有数据
        isData3: false, // 图表2是否有数据
        isShowLoading: false,
        chartOption: {}, // 图表的配置
        // chartOption1: {}, // 图表1的配置
        chartOption2: {}, // 图表2的配置
        chartOption3: {}, // 图表2的配置
      };
    },
    mounted() {
      this.getCharts();
    },
    methods: {
      /**
       * 获取图表数据
       */
      getCharts() {
        orderPage
          .queryRealData({
            tradeNo: this.tradeNo,
            startTime: this.startTime, //
            endTime: this.endTime, //
          })
          .then(res => {
            console.log('请求实时订单图表数据+++++', res);
            if (res && res.length > 0) {
              const realTimePileV = []; // 实时电压
              const realTimePileA = []; // 实时电流
              const realTimePower = []; // 实时功率
              const realTimeSoc = []; // 实时SOC
              // 处理实时数据
              res.forEach(item => {
                realTimePileV.push({ value: [item.time, item.pileV] }); // 保存实时电压
                realTimePileA.push({ value: [item.time, item.pileA] }); // 保存实时电流
                if (item.soc !== 0) {
                  realTimeSoc.push({ value: [item.time, item.soc] });
                }
                realTimePower.push({ value: [item.time, item.power] });
              });
              // 需求SOC图
              // if (realTimeSoc.length) {
              //   this.isData1 = true;
              //   const socV = [
              //     {
              //       name: 'SOC',
              //       data: realTimeSoc,

              //       hoverAnimation: false,
              //       symbolSize: 6,
              //       type: 'line',
              //       itemStyle: {
              //         emphasis: {
              //           borderWidth: 3,
              //           color: '#4D8CFD',
              //         },
              //         normal: {
              //           color: '#4D8CFD',
              //           lineStyle: {
              //             color: '#4D8CFD',
              //           },
              //         },
              //       },
              //     },
              //   ];
              //   this.chartOption1 = this.getChartOption(socV, realTimeSoc.length, ['SOC'], ['%']);
              // }
              // 需求功率图
              if (realTimePower.length) {
                this.isData2 = true;
                const powerV = [
                  {
                    name: '功率',
                    data: realTimePower,
                    hoverAnimation: false,
                    symbolSize: 6,

                    type: 'line',
                    itemStyle: {
                      emphasis: {
                        borderWidth: 3,
                        color: '#4D8CFD',
                      },
                      normal: {
                        color: '#4D8CFD',
                        lineStyle: {
                          color: '#4D8CFD',
                        },
                      },
                    },
                  },
                ];
                this.chartOption2 = this.getChartOption(
                  powerV,
                  realTimePower.length,
                  ['功率'],
                  ['KW']
                );
              }
              // 需求电压-实际电压图
              if (realTimePileV.length) {
                this.isData3 = true;
                const seriesV = [
                  {
                    name: '电压',
                    data: realTimePileV,
                    hoverAnimation: false,
                    symbolSize: 6,

                    type: 'line',
                    itemStyle: {
                      emphasis: {
                        borderWidth: 3,
                        color: '#4D8CFD',
                      },
                      normal: {
                        color: '#4D8CFD',
                        lineStyle: {
                          color: '#4D8CFD',
                        },
                      },
                    },
                  },
                  {
                    name: '电流',
                    data: realTimePileA,
                    hoverAnimation: false,
                    symbolSize: 6,

                    type: 'line',
                    itemStyle: {
                      emphasis: {
                        borderWidth: 3,
                        color: '#15c350',
                      },
                      normal: {
                        color: '#15c350',
                        lineStyle: {
                          color: '#15c350',
                        },
                      },
                    },
                  },
                ];
                this.chartOption3 = this.getChartOption(
                  seriesV,
                  realTimePileV.length,
                  ['电压(V)/电流(A)'],
                  ['V', 'A']
                );
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       *
       */
      getChartOption(seriesData, dataNum, yName, unit) {
        console.log(seriesData);
        console.log('dataNum', dataNum);
        const chartOption = {
          grid: {
            // 网格
            top: '50px',
            bottom: '20px',
            left: '20px',
            right: '30px',
            containLabel: true, // 是否显示刻度
          },
          tooltip: {
            trigger: 'axis',
            formatter: params => {
              // 显示样式
              console.log('params===', params);
              let html = `<div class="tbox"><div>${params[0].value[0]}</div>`;
              params.forEach((item, index) => {
                html += `<div class="showBox">
                          <div style="display:flex;">
                              <div style="width: 6px;height: 6px;margin:8px 8px 0 0;background: ${item.color};border-radius: 5px;"></div>
                              <span style="flex:1;color:${item.color}">${item.seriesName}：</span>
                              <span style="float:right;">${item.value[1]} ${unit[index]}</span>
                          </div>
                      </div>`;
              });
              html += '</div>';
              return html;
            },
          },
          legend: {
            // 图例
            show: true,
            selectedMode: false, // 默认不开启图列数据切换，否则会影响自定义字段的tooltip显示
            top: '20px',
            right: '30px',
            itemWidth: 9,
            itemHeight: 9,
            icon: 'circle',
            textStyle: {
              color: '#4A4A4A', // 图例文字颜色
            },
          },
          // 滚动条
          dataZoom: {
            type: 'slider',
            brushSelect: false,
            // 区域缩放
            show: dataNum > 14, // 默认显示14条数据，如果大于14条，则出现区域缩放
            backgroundColor: '#F0F0F0',
            fillerColor: '#CACACA',
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            borderColor: '#F6F7F8',
            handleIcon: 'path://path://M100, 100m -75, 0a75,75 0 1,0 150,0a75,75 0 1,0 -150,0',
            textStyle: {
              color: 'transparent',
            },
            height: 6,
            handleSize: '65%',
            handleStyle: {
              color: '#CACACA',
              shadowColor: 'rgba(77, 140, 253, 0.5)',
              borderColor: '#CACACA',
            },
            bottom: '40px',
            start: 0,
            end: dataNum <= 14 ? 100 : Math.floor((14 / dataNum) * 100), // 控制一页显示多少条数据
          },
          xAxis: {
            type: 'time', // 类型
            // name: '时间',
            nameLocation: 'end',
            show: true,
            boundaryGap: false,
            axisLine: {
              // 坐标轴轴线相关设置
              show: true,
              lineStyle: {
                color: '#9B9B9B',
              },
            },
            axisLabel: {
              margin: 14,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              alignWithLabel: true,
              lineStyle: {
                color: '#4A4A4A',
              },
            },
          },
          yAxis: {
            // name: '电压(V)',
            name: yName,
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#E2E0E0',
              },
            },
          },
          series: seriesData,
        };
        return chartOption;
      },
    },
  };
</script>

<style lang="scss">
  .echarts-detail {
    .cardview-box {
      .cardview-head {
        .detail-head.flex.cardview-head-box {
          margin: 11.5px 0;
        }
      }
    }

    margin-top: 12px;
    .cardview-box {
      padding: 0 10px !important;
    }
    .el-tabs__nav {
      text-align: center;
    }
    box-shadow: none;
    .ykc-table .el-table {
      border-top: 0;
    }

    .ykc-tabs {
      margin-top: -20px;
      .tabs.el-tabs {
        margin-left: -10px;
      }
    }
    .el-tabs--border-card > .el-tabs__content {
      padding: 0;
    }
    .ykc-tabs .el-tabs--border-card > .el-tabs__header .el-tabs__item {
      color: #000000;
      font-weight: 400;
    }

    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
      color: #9b9b9b;
    }

    .echarts-line {
      display: flex;
      flex-direction: column;
      padding: 0 10px;

      margin-top: 10px;
      padding-bottom: 10px;
      .row {
        display: flex;
      }
      .row:nth-child(1) {
        margin-bottom: 20px;
      }
      .charts-box {
        width: 50%;
        height: 338px;
        border: 1px solid #e2e0e0;
      }
      .charts-box:nth-child(1) {
        margin-right: 20px;
      }
      .charts-box:nth-child(2) {
        margin-right: 20px;
      }
    }
  }
</style>
